import { OrganizationType } from "models";
import { CompanyErrors } from "pages/company/errors";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as CompanyService from "services/company";

type SectorsContextType = {
  sectors: OrganizationType[] | null;
  getOrgnizaitons: () => Promise<void>;
};

type SectorsProviderType = {
  children: ReactNode;
};

const SectorsContext = createContext<SectorsContextType>(
  {} as SectorsContextType
);

const SectorsProvider = ({ children }: SectorsProviderType) => {
  const [sectors, setSectors] = useState<OrganizationType[] | null>(null);
  const { error, warning } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();

  const errorsResolver = useMemo(
    () => new CompanyErrors({ error, warning }, t),
    [error, warning, t]
  );

  const getOrgnizaitons = useCallback(async () => {
    const companyId = user?.selectedCompany?._id as string;
    if(!companyId) return;
    await CompanyService.getOrganizationsNew(companyId)
      .then(({ data }) => setSectors(data))
      .catch(errorsResolver.defaultError);
  }, [user, errorsResolver]);

  useEffect(() => {
    const companyId = user?.selectedCompany?._id;

    if (!sectors && companyId) {
      getOrgnizaitons();
    }
  }, [sectors, user, getOrgnizaitons]);

  return (
    <SectorsContext.Provider value={{ sectors, getOrgnizaitons }}>
      {children}
    </SectorsContext.Provider>
  );
};

export const useSectors = () => useContext(SectorsContext);

export default SectorsProvider;
