import { ReactComponent as LeftArrowIcon } from "assets/icons/leftArrow.svg";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as LogoIcon } from "assets/logoIcon.svg";
import { ReactComponent as AvatarIcon } from "assets/profile.svg";
import { ReactComponent as CompanyIcon } from "assets/sidebar/company.svg";
import { ReactComponent as ExportIcon } from "assets/sidebar/export.svg";
import { ReactComponent as GraficIcon } from "assets/sidebar/grafic.svg";
import { ReactComponent as PrivacyPolicyIcon } from "assets/sidebar/privacy-policy.svg";
import { ReactComponent as JourneyIcon } from "assets/sidebar/journey.svg";
import { ReactComponent as ModelIcon } from "assets/sidebar/model.svg";
import Avatar from "components/avatar";
import NavPercent from "components/navPercent";
import NavSector from "components/navSector";
import { Divisor } from "global.styles";
import { useAuth } from "providers/auth";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getFirstAndLastWord, ls } from "utils";
import NavDropdown from "../navDropdown";
import NavItem from "../navItem";
import { Nav, NavButton, SideBarConainer, FooterContent, FooterItem, Anchor } from "./styles";
import * as process from "process";

const Version = process.env.REACT_APP_VERSION || "0.00";
const menuKey = "isCollapsed";


const SideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(
    ls.getItem(menuKey) === "true"
  );
  const { user, signOut } = useAuth();
  const { t } = useTranslation("components");

  const profile = useMemo(
    () => [
      {
        label: t("sideBar.profile"),
        path: "profile",
      },
      {
        label: t("exit"),
        action: signOut,
      },
    ],
    [signOut, t]
  );

  const company = useMemo(
    () => [
      {
        label: t("sideBar.preference"),
        path: "company",
      },
    ],
    [t]
  );

  const navItems = useMemo(
    () => [
      {
        icon: <JourneyIcon />,
        name: t("sideBar.journey"),
        path: "journey",
      },
      {
        icon: <GraficIcon />,
        name: t("sideBar.report"),
        path: "report",
      },
      {
        icon: <ModelIcon />,
        name: t("sideBar.config"),
        path: "config",
      },
      {
        icon: <ExportIcon />,
        name: t("sideBar.export"),
        path: "export",
      },
    ],
    [t]
  );

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    ls.setItem(menuKey, `${!isCollapsed}`);
  }, [isCollapsed]);

  return (
    <SideBarConainer isCollapsed={isCollapsed}>
      <NavButton
        isCollapsed={isCollapsed}
        icon={<LeftArrowIcon />}
        onClick={handleToggleCollapse}
      />
      <NavLink to="/">
        {isCollapsed ? <LogoIcon width="100%" /> : <Logo width="100%" />}
      </NavLink>
      <Nav>
        <NavDropdown
          item={profile}
          justIcon={isCollapsed}
          label={t("sideBar.user")}
          text={getFirstAndLastWord(user?.name) ?? ""}
          icon={
            <Avatar
              small={true}
              src={user?.profilePhoto}
              icon={<AvatarIcon />}
            />
          }
        />
        <NavDropdown
          item={company}
          justIcon={isCollapsed}
          label={t("sideBar.company")}
          text={user?.selectedCompany.fantasyName ?? ""}
          icon={<CompanyIcon />}
        />
        <NavSector justIcon={isCollapsed} />
        <NavLink to="plan">
          {({ isActive }) => (
            <NavPercent
              consumption={0}
              isActive={isActive}
              justIcon={isCollapsed}
              name={t("sideBar.plan")}
            />
          )}
        </NavLink>
        <Divisor />
        {navItems?.map((item, index) => (
          <NavLink key={index} to={item.path}>
            {({ isActive }) => (
              <NavItem
                icon={item.icon}
                name={item.name}
                isActive={isActive}
                justIcon={isCollapsed}
              />
            )}
          </NavLink>
        ))}
        <Divisor />
        <Anchor
          href="https://ligo.cloud/politica-de-privacidade/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <NavItem icon="" name={t("sideBar.police")} justIcon={isCollapsed} />
        </Anchor>
        <FooterContent>
          <Divisor />     
          <FooterItem>
            <span>{t("footer.version")} {Version}</span>
          </FooterItem>                 
        </FooterContent>
      </Nav>
    </SideBarConainer>
  );
};

export default SideBar;
